import { PERCENTAGE } from "./consts"

export const AdditionalFeesSwitcher = ({ typeSwitcher, onChangeType }) => {
    return (
      <div className="flex items-center">
        <div
          onClick={onChangeType}
          className="relative w-28 h-7 flex rounded-full bg-purple-450 cursor-pointer p-1"
        >
          <div
            className={`absolute top-1 bottom-1 ${
                typeSwitcher === PERCENTAGE ? "left-1" : "right-1"
            } w-[calc(50%-0.25rem)] bg-gray-100 opacity-75 z-10 rounded-full`}
            style={{ width: "calc(50% - 0.25rem)" }}
          />
          <div
            className={`flex-1 flex items-center justify-center font-semibold text-sm text-white relative z-20 select-none`}
          >
            %
          </div>
          <div
            className={`flex-1 flex items-center justify-center font-semibold text-sm text-white relative z-20 select-none`}
          >
            $
          </div>
        </div>
      </div>
    )
}