import { ArrowNarrowLeftIcon, PencilIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import { PERCENTAGE, DOLLAR, AMAZON_RETAIL, ADDITIONAL_FEES, UNITS, TOTAL_ADDITIONAL_FEES } from './consts'
import { upsertProductAsync } from './powerProfitToolSlice'
import AdditionalFeesEditor from "./AdditionalFeesEditor"
import MetricItem from './MetricItem'
import Btn from './../../components/general/Btn'
import LockedFeature from "./../../components/general/LockedFeature"

const ViewMetrics = ({ product, closeHandler, setOpen, setEditingItem }) => {
  const { currentUser } = useSelector((state) => state.auth)
  const isBasic = currentUser?.role === 'basic'
  const dispatch = useDispatch()

  const [type, setType] = useState(PERCENTAGE);
  const [percentage, setPercentage] = useState(Number(product[ADDITIONAL_FEES]));
  // const [additionalFees, setAdditionalFees] = useState(Number(product[ADDITIONAL_FEES]))

  const [rangeTotalAdditionalFees, setRangeTotalAdditionalFees] = useState(product.totalAdditionalFees);

  useEffect(() => {
    setPercentage(product[ADDITIONAL_FEES])
    setRangeTotalAdditionalFees(product[TOTAL_ADDITIONAL_FEES])
  }, [product[ADDITIONAL_FEES], product[TOTAL_ADDITIONAL_FEES]]);

  const toggleType = React.useCallback(() => {
    setType(type => type === PERCENTAGE ? DOLLAR : PERCENTAGE);
  }, []);

  const dollarValue = Number(((Number(product[AMAZON_RETAIL]) * percentage) / 100).toFixed(2))

  useEffect(() => {
    setRangeTotalAdditionalFees(dollarValue * product[UNITS])
  }, [dollarValue])

  // useEffect(() => {
  //   if (type === DOLLAR) {
  //     setAdditionalFees(dollarValue)
  //   } else {
  //     setAdditionalFees(percentage)
  //   }
  // }, [type, percentage])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div>
      <button
        className="flex items-center border-2 border-gray-400 px-4 py-2 rounded-lg mb-8"
        onClick={closeHandler}
      >
        <ArrowNarrowLeftIcon className="w-4 h-4 mr-2" />
        back to products
      </button>

      <div key={product.id} className="flex flex-col mb-10 gap-4">
        <div className="w-full rounded-md border py-6 px-6">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl">{product.title}</h1>
            <button
              onClick={() => {
                setEditingItem(product)
                setOpen(true)
              }}
              className="inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
            >
              <PencilIcon className="h-5 w-5 mr-2" />
              edit
            </button>
          </div>
          <p className="mt-6">{product.description}</p>
          <div className='mt-6 mb-6'>
            <div className="mb-6 h-0.5 bg-gray-300 rounded-xl" />
            <h2 className="mt-6 mb-4 text-2xl text-gray-900 text-center">
              Additional Fees Per Unit
            </h2>
            {isBasic ?
              <div className="flex justify-center flex-col items-center">
                <ReactTooltip id={"additional-fees-tooltip-link"}>Click here to unlock this feature</ReactTooltip>
                <LockedFeature dataForId={"additional-fees-tooltip-link"}/>
              </div>
                :
              <>
                <AdditionalFeesEditor
                  product={product}
                  type={type} 
                  onTypeToggle={toggleType}
                  percentage={percentage}
                  onPercentageChange={setPercentage}
                  dollarValue={dollarValue}
                />
                <div className='mt-6 mb-6 flex justify-end'>
                  <Btn
                    onClick={() => dispatch(upsertProductAsync({ product: {...product, [ADDITIONAL_FEES]: percentage }}))}
                    row={true}
                  >
                    <span>Save</span>
                  </Btn>
                </div>
              </>
            }
          </div>
        </div>
        <div className="w-full rounded-md border flex flex-col items-center py-2 px-2 bg-gray-300">
          <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center sm:justify-normal items-center gap-0.5 md:gap-1 xl:gap-4">
            <MetricItem
              itemName="Estimated Bulk Order Profit"
              itemValue={product.potentialProfit}
              fixedPoints={2}
              dollar={true}
              tooltip="estimated-profit-margin-tooltip"
              enableValueHighlighting={(Number(product.potentialProfit) || 0) !== 0} 
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Profit Margin"
              itemValue={product.profitMargin}
              percentage={true}
              tooltip="profit-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Estimated Profit Per Unit"
              itemValue={product.netProfitPerUnit}
              fixedPoints={2}
              dollar={true}
              tooltip="estimated-profit-per-unit-margin-tooltip"
              enableValueHighlighting={(Number(product.netProfitPerUnit) || 0) !== 0} 
            />
          </div>
          <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center sm:justify-normal items-center gap-0.5 md:gap-2 xl:gap-4">
            <MetricItem
              itemName="Total Manufacturing Cost"
              itemValue={product.totalCost}
              fixedPoints={2}
              dollar={true}
              tooltip="total-manufacturing-cost-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Total Manufacturing, Shipping, & Customs Cost"
              itemValue={product.totalPlusShippingCost}
              fixedPoints={2}
              dollar={true}
              tooltip="total-manufacturing-shipping-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Total Cost Per Unit"
              itemValue={product.totalCostPerUnit}
              fixedPoints={2}
              dollar={true}
              tooltip="total-cost-per-unit-margin-tooltip"
            />
          </div>
          <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center sm:justify-normal items-center gap-0.5 md:gap-2 xl:gap-4">
            <MetricItem
              itemName="Amazon Fees Total"
              itemValue={product.totalAmazonFees}
              fixedPoints={2}
              dollar={true}
              tooltip="amazon-fees-total-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem 
              itemName="Additional Fees Total" 
              itemValue={rangeTotalAdditionalFees} 
              fixedPoints={2} 
              dollar={true} 
              tooltip="additional-fees-total-per-unit-margin-tooltip" 
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem 
              itemName="Amazon Payout Per Unit" 
              itemValue={product.amazonNet}
              isIncorrectValue={((Number(product.netProfitPerUnit) || 0) < 0 && (Number(product.amazonNet) || 0) > 0)}
              fixedPoints={2} 
              dollar={true} 
              tooltip="amazon-payout-per-unit-margin-tooltip" 
              errorTooltip={((Number(product.netProfitPerUnit) || 0) < 0 && (Number(product.amazonNet) || 0) > 0) ? "amazon-payout-per-unit-error-margin-tooltip" : ""}
              enableValueHighlighting={(Number(product.amazonNet) || 0) !== 0} 
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewMetrics
