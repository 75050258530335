import { ExclamationCircleIcon } from '@heroicons/react/solid'
import React from 'react'

const FormFieldTooltip = ({ dataFor, testId, label = "Learn more", isError = false }) => {
  return (
    <span className="ml-2 flex items-center space-x-1 cursor-pointer relative" data-tip data-for={dataFor} data-testid={testId}>
      {!isError && <img src="/images/question.svg" alt="content tooltip" width="17" height="15" />}
      {isError && <ExclamationCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />}
      <span className={`text-sm ${isError ? "text-red-800" : "text-gray-400"} whitespace-nowrap`}>{label}</span>
    </span>
  )
}

export default FormFieldTooltip
