import React from 'react'
import { PencilIcon } from '@heroicons/react/outline'
import MetricItem from '../MetricItem'
import Btn from './../../../components/general/Btn'

const ProductTab = ({ products, setOpen, setEditingItem, setMetricsViewProductId }) => {
  return (
    <div>
      {products?.map((product) => (
        <div key={product.id} className="flex flex-col sm:flex-row sm:gap-4 mb-10">
          <div className="w-full sm:w-3/5 rounded-md border py-6 px-6">
            <div className="flex justify-between items-center">
              <h1 className="text-4xl">{product.title}</h1>
              <button
                onClick={() => {
                  setEditingItem(product)
                  setOpen(true)
                }}
                className="inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
              >
                <PencilIcon className="h-5 w-5 mr-2" />
                edit
              </button>
            </div>
            <p className="mt-6">{product.description}</p>
          </div>
          <div className="w-full sm:w-2/5 rounded-md border flex flex-col items-center py-4 px-2">
            <div className="flex flex-col xl:flex-row">
              <MetricItem
                itemName="Estimated Bulk Order Profit"
                itemValue={product.potentialProfit}
                dollar={true}
                fixedPoints={2}
                tooltip="estimated-profit-margin-tooltip"
                type="default"
              />
              <div className="xl:h-12 border-b xl:border-r border-black xl:mt-12"></div>
              <MetricItem
                itemName="Profit Margin"
                itemValue={product.profitMargin}
                percentage={true}
                tooltip="profit-margin-tooltip"
                type="default"
              />
            </div>
            <div className="w-32 my-2">
            <Btn
              onClick={() => setMetricsViewProductId(product.id)}
              row={true}
              full={true}
              >
                View Metrics
              </Btn>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductTab
